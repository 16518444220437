/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageWrapper from "components/pageWrapper";
import styles from "styles/pages/leaderboards.module.scss";
import Header from "components/header";
import LeaderboardFilters from "components/leaderboardFilters";
import { Avatar, Table, Typography } from "antd";
import { getLeaderboard } from "utils/api";
import { FILTERS_SEARCH_PARAMS, FILTER_TYPE } from "utils/constants";
import { useSearchParams } from "react-router-dom";
import { getStartEndDateFromDateFilter, slugify } from "utils/utils";

const { Paragraph } = Typography;

const InactiveEmployees = () => {
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [currentQueryParameters] = useSearchParams();

  useEffect(() => {
    //List employees
    const dateRange = currentQueryParameters.get(FILTERS_SEARCH_PARAMS.DATE);
    fetchData(getStartEndDateFromDateFilter(dateRange));
  }, []);

  useEffect(() => {
    if (rawData) {
      filterAndSort(rawData);
    }
  }, [currentQueryParameters]);

  const fetchData = (dateRangeParams) => {
    setLoading(true);
    return getLeaderboard(dateRangeParams).then((d) => {
      const withTotal = {
        ...d,
        zeroScoreUsers: d.zeroScoreUsers.reduce(
          (acc, element) => [
            ...acc,
            {
              ...element,
              data: {
                ...element.data,
                points: {
                  ...element.data.points,
                  total:
                    element.data.points.given + element.data.points.received,
                },
                values: (Object.keys(element.data.values) || []).reduce(
                  (accu, e) => [
                    ...accu,
                    {
                      ...element?.data?.values[e],
                      name: e,
                      slug: slugify(e),
                      points: {
                        ...element?.data?.values[e].points,
                        total:
                          element?.data?.values[e].points.given +
                          element?.data?.values[e].points.received,
                      },
                    },
                  ],
                  []
                ),
              },
            },
          ],
          []
        ),
      };
      setRawData(withTotal);
      filterAndSort(withTotal);
      setLoading(false);
    });
  };

  const filterAndSort = (d) => {
    if (!d) {
      return d;
    }
    const type = currentQueryParameters.get(FILTERS_SEARCH_PARAMS.TYPE);
    const valueFilter = currentQueryParameters.get(FILTERS_SEARCH_PARAMS.VALUE);
    const u = d.zeroScoreUsers.reduce(
      (acc, element) => [
        ...acc,
        {
          ...element,
          toSort:
            !valueFilter || valueFilter === FILTER_TYPE.ALL
              ? element.data
              : (element.data.values || []).find((v) => {
                  return v.slug === valueFilter;
                }) || { points: { received: 0, given: 0, total: 0 } },
        },
      ],
      []
    );

    switch (type) {
      case FILTER_TYPE.GIVEN:
        u.sort((a, b) => {
          if (a.toSort.points.given < b.toSort.points.given) {
            return 1;
          }
          if (a.toSort.points.given > b.toSort.points.given) {
            return -1;
          }
          return 0;
        });

        break;
      case FILTER_TYPE.RECEIVED:
        u.sort((a, b) => {
          if (a.toSort.points?.received < b.toSort.points?.received) {
            return 1;
          }
          if (a.toSort.points?.received > b.toSort.points?.received) {
            return -1;
          }
          return 0;
        });
        break;
      default:
      case FILTER_TYPE.ALL:
        u.sort((a, b) => {
          if (a.toSort.points?.total < b.toSort.points?.total) {
            return 1;
          }
          if (a.toSort?.points?.total > b.toSort?.points?.total) {
            return -1;
          }
          return 0;
        });
        break;
    }
    // Trick to get Table to update the view with updated variable
    setFilteredData([...u]);
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <div className={styles.titleWrapper}>
          <div className={styles.titleContent}>
            <h1 className={styles.title}>Inactive employees</h1>
            <div className={styles.subtitle}>
              List of people who didn't participate in recognition yet.
            </div>
          </div>
        </div>
        <div className={styles.filtersWrapper}>
          <LeaderboardFilters
            filterAndSort={() => {
              filterAndSort(rawData);
            }}
            fetch={fetchData}
          />
        </div>
        <div className={styles.tableWrapper}>
          <Table
            size="small"
            loading={loading}
            dataSource={filteredData}
            pagination={{ showTotal: (total) => `${total} results` }}
            columns={[
              {
                title: "",
                dataIndex: "profilePicture",
                render: (url, record, index) => (
                  <>
                    <Avatar src={url} size="50" className={styles.avatar} />
                  </>
                ),
                width: 50,
              },
              {
                title: `Name (${(filteredData || []).length})`,
                dataIndex: "name",
                render: (name, element, index) => (
                  <a href={`/#/users/${element._id}`}>{name}</a>
                ),
              },
              {
                title: "Email",
                dataIndex: "email",
                render: (email) => <Paragraph copyable>{email}</Paragraph>,
              },
            ]}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default InactiveEmployees;
