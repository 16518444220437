/* eslint-disable react-hooks/exhaustive-deps */
import Header from "components/header";
import PageWrapper from "components/pageWrapper";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "styles/pages/reward-details.module.scss";
import ProfileContext from "components/profileContext";
import { Button } from "antd";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import ClaimButton from "components/claimButton";
import Loader from "components/loader";
import RewardListContext from "components/rewardListContext";

const RewardDetails = () => {
  const [reward, setReward] = useState(null);
  const { id } = useParams();
  const { profile } = useContext(ProfileContext);
  const { rewards } = useContext(RewardListContext);

  useEffect(() => {
    const r = rewards.find((r) => r._id === id);
    setReward(r);
  }, []);

  return (
    <>
      <Header />
      <PageWrapper>
        <div>
          <a href="/#">
            <Button icon={<LeftOutlined />} type="link" className={styles.back}>
              View all rewards
            </Button>
          </a>
        </div>
        {reward ? (
          <div className={styles.wrapper}>
            <div className={styles.leftWrapper}>
              <div
                className={styles.picture}
                style={{
                  backgroundImage: `url(${
                    reward.image || "/img/default_reward.png"
                  })`,
                }}
              ></div>
              <ClaimButton reward={reward} />
              {profile.isAdmin && (
                <a href={`/#/edit-reward/${reward._id}`}>
                  <Button
                    className={styles.edit}
                    type="link"
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>
                </a>
              )}
            </div>
            <div className={styles.rightWrapper}>
              <h1 className={styles.title}>{reward.title}</h1>
              <div>{reward.description}</div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </PageWrapper>
    </>
  );
};

export default RewardDetails;
