import React from "react";
import { Cell, Pie as PieElement, PieChart, Legend } from "recharts";

const Pie = ({ data, width = 480, simpleLabels = false }) => {
  if (!data) return <></>;
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#39a778",
    "#0d5181",
    "#2e2836",
  ];
  const MAX_LINE_LEGEND = 18;
  const CHAR_PER_LINE = 18;
  const RADIAN = Math.PI / 180;

  const renderLegend = (value, entry, index) => (
    <span>{`${(entry.payload.percent * 100).toFixed(0)}% - ${value}`}</span>
  );
  const renderCustomizedLabel = ({
    name,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    let textDisplay = <></>;

    if (name.length > MAX_LINE_LEGEND) {
      for (let i = 0; i <= Math.floor(name.length / CHAR_PER_LINE); i++) {
        textDisplay = (
          <>
            {textDisplay}
            <tspan dy="1.1em" x={x}>
              {name.substring(i * MAX_LINE_LEGEND, (i + 1) * CHAR_PER_LINE)}
            </tspan>
          </>
        );
      }
    } else {
      textDisplay = (
        <tspan dy={y < 200 ? "0em" : "1.2em"} x={x}>
          {name}
        </tspan>
      );
    }

    const ypositionTop = y - 24 * (1 + Math.floor(name.length / CHAR_PER_LINE));
    const ypositionBottom = y + (1 + Math.floor(name.length / CHAR_PER_LINE));

    const t = (
      <text
        fontSize="small"
        x={x}
        y={y > 200 ? ypositionBottom : ypositionTop}
        fill={COLORS[index]}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {textDisplay}
      </text>
    );
    const p = (
      <text
        x={x}
        y={y}
        fill={COLORS[index]}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );

    return (
      <>
        {p}
        {simpleLabels ? "" : t}
      </>
    );
  };
  return (
    <PieChart width={width} height={400}>
      <PieElement
        data={data}
        cx="50%"
        cy="50%"
        labelLine={true}
        label={renderCustomizedLabel}
        outerRadius={76}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </PieElement>
      {simpleLabels && (
        <Legend
          formatter={renderLegend}
          align="center"
          layout="horizontal"
          verticalAlign="bottom"
        />
      )}
    </PieChart>
  );
};

export default Pie;
