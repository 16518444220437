import React from "react";
import ReactDOM from "react-dom/client";
import "styles/global.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1f3a45",
          borderRadius: 8,
          fontFamily: "'silka', sans-serif",
          boxShadow: "none",
          colorLink: "#158c59",
          controlHeight: 40,
          fontSize: 16,
        },
      }}
    >
      <App />
    </ConfigProvider>
  </HashRouter>
);

// reportWebVitals();
