/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageWrapper from "components/pageWrapper";
import styles from "styles/pages/leaderboards.module.scss";
import Header from "components/header";
import LeaderboardFilters from "components/leaderboardFilters";
import { Avatar, Table } from "antd";
import { getLeaderboard } from "utils/api";
import SeedIcon from "components/seedIcon";
import AlignCenter from "components/alignCenter";
import { FILTERS_SEARCH_PARAMS, FILTER_TYPE } from "utils/constants";
import { useSearchParams } from "react-router-dom";
import { getStartEndDateFromDateFilter, slugify } from "utils/utils";
const DEFAULT_PAGE_SIZE = 10;
const Leaderboards = () => {
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [currentQueryParameters] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    //List employees
    const dateRange = currentQueryParameters.get(FILTERS_SEARCH_PARAMS.DATE);
    fetchData(getStartEndDateFromDateFilter(dateRange));
  }, []);

  useEffect(() => {
    if (rawData) {
      filterAndSort(rawData);
    }
  }, [currentQueryParameters]);

  const fetchData = (dateRangeParams) => {
    setLoading(true);
    return getLeaderboard(dateRangeParams).then((d) => {
      const withTotal = {
        ...d,
        users: d.users.reduce(
          (acc, element) => [
            ...acc,
            {
              ...element,
              data: {
                ...element.data,
                points: {
                  ...element.data.points,
                  total:
                    element.data.points.given + element.data.points.received,
                },
                values: (Object.keys(element.data.values) || []).reduce(
                  (accu, e) => [
                    ...accu,
                    {
                      ...element?.data?.values[e],
                      name: e,
                      slug: slugify(e),
                      points: {
                        ...element?.data?.values[e].points,
                        total:
                          element?.data?.values[e].points.given +
                          element?.data?.values[e].points.received,
                      },
                    },
                  ],
                  []
                ),
              },
            },
          ],
          []
        ),
      };
      setRawData(withTotal);
      filterAndSort(withTotal);
      setLoading(false);
    });
  };

  const filterAndSort = (d) => {
    if (!d) {
      return d;
    }
    const type = currentQueryParameters.get(FILTERS_SEARCH_PARAMS.TYPE);
    const valueFilter = currentQueryParameters.get(FILTERS_SEARCH_PARAMS.VALUE);
    const u = d.users.reduce(
      (acc, element) => [
        ...acc,
        {
          ...element,
          toSort:
            !valueFilter || valueFilter === FILTER_TYPE.ALL
              ? element.data
              : element.data.values.find((v) => {
                  return v.slug === valueFilter;
                }) || { points: { received: 0, given: 0, total: 0 } },
        },
      ],
      []
    );

    switch (type) {
      case FILTER_TYPE.GIVEN:
        u.sort((a, b) => (b.toSort.points?.given || 0) - (a.toSort.points?.given || 0));
        break;
      case FILTER_TYPE.RECEIVED:
        u.sort((a, b) => (b.toSort.points?.received || 0) - (a.toSort.points?.received || 0));
        break;
      default:
      case FILTER_TYPE.ALL:
        u.sort((a, b) => {
          const diff = (b.toSort.points?.total || 0) - (a.toSort.points?.total || 0);
          if (diff !== 0) return diff;
          return (b.toSort.points?.received || 0) - (a.toSort.points?.received || 0);
        });
        break;
    }
    // Trick to get Table to update the view with updated variable
    setFilteredData([...u]);
  };

  const onTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const queryType = currentQueryParameters.get(FILTERS_SEARCH_PARAMS.TYPE);
  return (
    <>
      <Header />
      <PageWrapper>
        <div className={styles.titleWrapper}>
          <div className={styles.titleContent}>
            <h1 className={styles.title}>Leaderboards</h1>
            <div className={styles.subtitle}>
              List ranking of best Givers, Receivers and top achievers per
              value.
            </div>
          </div>
        </div>
        <div className={styles.filtersWrapper}>
          <LeaderboardFilters
            filterAndSort={() => {
              filterAndSort(rawData);
            }}
            fetch={fetchData}
          />
        </div>
        <div className={styles.tableWrapper}>
          <Table
            onChange={onTableChange}
            size="small"
            loading={loading}
            dataSource={filteredData || []}
            className={styles.table}
            scroll={{ x: 1000 }}
            rowClassName={(record, index) =>
              page === 1
                ? index === 0
                  ? styles.rank0
                  : index === 1
                  ? styles.rank1
                  : index === 2
                  ? styles.rank2
                  : ""
                : ""
            }
            columns={[
              {
                // fixed: "left",
                title: "Rank",
                dataIndex: "_id",
                className: styles.rank,
                render: (id, el, index) => (
                  <div
                    className={`${styles.rankContent} ${
                      styles[`rankContent${index + (page - 1) * pageSize}`]
                    }`}
                  >{`#${index + 1 + (page - 1) * pageSize}`}</div>
                ),
                width: 60,
              },
              {
                // fixed: "left",
                title: "",
                dataIndex: "profilePicture",
                render: (url, record, index) => (
                  <>
                    <Avatar src={url} size="50" className={styles.avatar} />
                    {/* {index === 0 ? (
                      <TrophyFilled className={styles.trophy} />
                    ) : (
                      <></>
                    )} */}
                  </>
                ),
                width: 60,
              },
              {
                title: `Name (${(filteredData || []).length})`,
                dataIndex: "name",
                render: (name, element, index) => (
                  <a
                    href={`/#/users/${element._id}`}
                    className={index <= 2 ? styles.bold : ""}
                  >
                    {name}
                  </a>
                ),
              },
              {
                title: (
                  <AlignCenter>
                    <SeedIcon /> Received
                  </AlignCenter>
                ),
                dataIndex: "received",
                className: `${styles.whiteBackground} ${styles.center} ${
                  queryType === FILTER_TYPE.RECEIVED ? styles.coloredColumn : ""
                }`,
                render: (received, element) =>
                  element.toSort?.points?.received || 0,
              },
              {
                title: (
                  <AlignCenter>
                    <SeedIcon /> Given
                  </AlignCenter>
                ),
                className: `${styles.whiteBackground} ${styles.center} ${
                  queryType === FILTER_TYPE.GIVEN ? styles.coloredColumn : ""
                }`,
                dataIndex: "given",
                render: (given, element) => element.toSort?.points?.given || 0,
              },
              {
                title: <AlignCenter>Total Score</AlignCenter>,
                className: `${styles.whiteBackground} ${styles.center} ${
                  queryType === FILTER_TYPE.ALL ? styles.coloredColumn : ""
                }`,
                dataIndex: "score",
                render: (score, element) => element.toSort?.points?.total || 0,
              },
            ]}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default Leaderboards;
