import styles from "styles/components/numberVignette.module.scss";

const NumberVignette = ({ label, value, background = "#e3ffe3", suffix }) => {
  const numberWithComma = value.toLocaleString("en-US");

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner} style={{ backgroundColor: background }}>
        <div className={styles.value}>
          {numberWithComma}
          {suffix && <span className={styles.suffix}>{suffix}</span>}
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  );
};

export default NumberVignette;
