import { Button, Form, Input, InputNumber, Modal, Upload, message } from "antd";
import { InboxOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import styles from "styles/components/rewardForm.module.scss";
import { addReward, updateReward } from "utils/api";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileContext from "./profileContext";
import { getBase64 } from "utils/utils";
import { CLOUDINARY } from "utils/constants";
import RewardListContext from "./rewardListContext";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const RewardForm = ({ initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState({});
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);
  const navigate = useNavigate();
  const { profile } = useContext(ProfileContext);
  const { updateRewards } = useContext(RewardListContext);
  const initValues = {
    amount: 1,
    ...(initialValues || {}),
  };
  const isEdit = !!initialValues;

  const onFinish = (values) => {
    setLoading(true);
    const functionToUse = isEdit ? updateReward : addReward;
    const params = {
      ...values,
      ...(image && image.picUrl ? { image: image.picUrl } : {}),
      ...(isEdit ? { rewardId: initialValues._id } : {}),
      live: values.live !== undefined ? values.live : true,
    };
    functionToUse(params)
      .then((data) => {
        updateRewards();
        message.success(
          <span>
            <b>{data.title}</b>
            {` was ${isEdit ? "updated" : "created"} succesfuly`}
          </span>
        );
        // form.resetFields();
        setTimeout(() => {
          navigate("/rewards");
        }, 500);
      })
      .catch((e) => {
        console.error(e);
        message.error("Error while creating the reward, try again.");
      });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("Image must be smaller than 1MB");
    }
    return isJpgOrPng && isLt1M;
  };

  const onChangeUpload = (info) => {
    if (info.file.status === "uploading") {
      // setIsUploading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        // setIsUploading(false);
        setImage({ imageUrl, picUrl: info.file.response.secure_url });
      });
    }
    if (info.file.status === "error") {
      message.error("Error while uploading, please try again.");
      // setIsUploading(false);
      setImage({ imageUrl: null });
    }
  };

  return (
    <>
      <Form
        form={form}
        layout={"vertical"}
        name="control-hooks"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
        initialValues={initValues}
      >
        <Form.Item name="title" label="Name" rules={[{ required: true }]}>
          <Input placeholder="Name the reward" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <TextArea rows={4} placeholder="Describe what's in it." />
        </Form.Item>
        <Form.Item name="amount" label="Price 🌱" rules={[{ required: true }]}>
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label="Upload a picture of the reward (squared pictures work best):"
          valuePropName="image"
          getValueFromEvent={normFile}
        >
          <Upload
            beforeUpload={beforeUpload}
            onChange={onChangeUpload}
            maxCount={1}
            data={{
              upload_preset: CLOUDINARY.UPLOAD_PRESETS,
            }}
            listType="picture-card"
            action={`https://api.cloudinary.com/v1_1/${CLOUDINARY.CLOUD_NAME}/upload`}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            loading={loading}
          >
            {isEdit ? "Save changes" : "Add reward"}
          </Button>
        </Form.Item>
        {profile.isAdmin && isEdit && (
          <>
            <div className={styles.archiveWrapper}>
              <Button
                className={styles.archiveBtn}
                block
                type="primary"
                ghost
                danger
                icon={<InboxOutlined />}
                onClick={() => setShowConfirmArchive(true)}
              >
                {initialValues.live ? "Archive" : "Unarchive"}
              </Button>
              <div className={styles.archiveHint}>
                {initialValues.live ? (
                  <span>
                    Archiving the reward will hide it from the list but you’ll
                    still see the previous claims.
                  </span>
                ) : (
                  <span>
                    Unarchiving the reward will show it back into the available
                    rewards.
                  </span>
                )}
              </div>
            </div>
            <Modal
              onCancel={() => {
                setShowConfirmArchive(false);
              }}
              open={showConfirmArchive}
              title={
                initialValues.live ? "Confirm archive" : "Confirm Unarchive"
              }
              footer={[
                <Button onClick={() => setShowConfirmArchive(false)}>
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    onFinish({ ...initialValues, live: !initialValues.live });
                  }}
                >
                  {initialValues.live ? "Archive it" : "Unarchive it"}
                </Button>,
              ]}
            >
              {initialValues.live
                ? "Please confirm that you want to archive this reward."
                : "Please confirm that you want to put this reward back to life."}
            </Modal>
          </>
        )}
      </Form>
    </>
  );
};

export default RewardForm;
