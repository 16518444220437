/* eslint-disable react-hooks/exhaustive-deps */
import LogoSeed from "components/logoSeed";
import PageWrapper from "components/pageWrapper";
import { useContext, useEffect } from "react";
import styles from "styles/pages/logout.module.scss";
import posthog from "posthog-js";
import { isLocalhost, isSuperAdmin } from "utils/utils";
import ProfileContext from "components/profileContext";

const Logout = () => {
  const { profile } = useContext(ProfileContext);

  useEffect(() => {
    localStorage.removeItem("sessionId");
    if (!isLocalhost() && !isSuperAdmin(profile)) {
      posthog.reset();
    }
  }, []);
  return (
    <div>
      <PageWrapper>
        <div className={styles.content}>
          <LogoSeed />
          <h1 className={styles.title}>You've been successfuly logged out</h1>
        </div>
      </PageWrapper>
    </div>
  );
};

export default Logout;
