/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "components/pageWrapper";
import styles from "styles/pages/add-reward.module.scss";
import RewardForm from "components/rewardForm";
import Header from "components/header";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Loader from "components/loader";
import RewardListContext from "components/rewardListContext";

const EditReward = () => {
  const [reward, setReward] = useState(null);
  const { id } = useParams();
  const { rewards } = useContext(RewardListContext);
  useEffect(() => {
    setReward(rewards.find((r) => r._id === id));
  }, [rewards]);

  return (
    <>
      <Header />
      <PageWrapper>
        <div>
          <a href="/#">
            <Button icon={<LeftOutlined />} type="link" className={styles.back}>
              Back to all rewards
            </Button>
          </a>
        </div>
        <div className={styles.addRewardWrapper}>
          <h1 className={styles.title}>Edit a reward</h1>
          <div>
            {reward ? <RewardForm initialValues={reward} /> : <Loader />}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default EditReward;
