import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import styles from "styles/components/loader.module.scss";

const Loader = () => (
  <div className={styles.loading}>
    <LoadingOutlined />
  </div>
);

export default Loader;
