/* eslint-disable react-hooks/exhaustive-deps */
import AddReward from "pages/add-reward";
import EditReward from "pages/edit-reward";
import RewardDetails from "pages/reward-details";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProfileContext from "components/profileContext";
import { getProfile, listRewards } from "utils/api";
import { useEffect, useState } from "react";
import RewardListContext from "components/rewardListContext";
import styles from "styles/App.module.scss";
import Rewards from "pages/rewards";
import Insights from "pages/insights";
import UserDetails from "pages/user-details";
import Leaderboards from "pages/leaderboards";
import { isLocalhost, isSuperAdmin, slugify } from "utils/utils";
import InactiveEmployees from "pages/inactive-employees";
import posthog from "posthog-js";

const NotFound = () => (
  <div>
    <h2 style={{ textAlign: "center", marginTop: "24px" }}>Page not found</h2>
  </div>
);
const AppWrapper = () => {
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const location = useLocation();
  const updateProfile = () => {
    if (location.pathname.substring(0, 5) !== "/auth") {
      return getProfile()
        .then((data) => {
          if (!isLocalhost() && !isSuperAdmin(profileInfos?.profile)) {
            posthog.identify(data.userId, {
              name: data.name,
              workspaceId: data.workspaceId,
              workspaceName: data.workspaceName,
              workspaceTz: data.workspaceTz,
              isAdmin: data.isAdmin,
            });
            posthog.group("workspace", data.workspaceId, {
              name: data.workspaceName,
              tz: data.workspaceTz,
            });
          }
          return setProfileInfo({
            profile:
              {
                ...data,
                companyValues: data.companyValues.reduce(
                  (acc, element) => [
                    ...acc,
                    { ...element, slug: slugify(element.name) },
                  ],
                  []
                ),
              } || {},
            updateProfile,
          });
        })
        .catch((e) => {
          console.error("error in app", e);
        });
    }
  };

  const updateRewards = () =>
    listRewards()
      .then((data) => setRewardList({ rewards: data, updateRewards }))
      .catch((e) => {
        console.error("error in app", e);
      });

  const [profileInfos, setProfileInfo] = useState({
    profile: {},
    updateProfile: updateProfile,
  });

  const [rewardList, setRewardList] = useState({
    rewards: [],
    updateRewards: updateRewards,
  });

  useEffect(() => {
    updateProfile().then(() => {
      setFirstLoadDone(true);
      updateRewards();
    });
    if (!isLocalhost() && !isSuperAdmin(profileInfos?.profile)) {
      posthog.init("phc_m9EPHhlRfYvbAscGl8HFLKTRfjUxEvFds32lXC8h5RD", {
        api_host: "https://eu.posthog.com",
      });
    }
  }, []);

  const sess = localStorage.getItem("sessionId");
  if (!sess || sess.length === 0) {
    window.location.href = "/#/auth";
    window.location.reload();
  }

  return (
    <>
      <ProfileContext.Provider value={profileInfos}>
        <RewardListContext.Provider value={rewardList}>
          <div className={styles.app}>
            {firstLoadDone && (
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate
                      to={
                        profileInfos.profile?.isAdmin ? "/insights" : "/rewards"
                      }
                      replace
                    />
                  }
                />
                <Route path="/leaderboards" element={<Leaderboards />} />
                <Route
                  path="/inactive-employees"
                  element={<InactiveEmployees />}
                />
                <Route path="/rewards" element={<Rewards />} />
                <Route path="/insights" element={<Insights />} />
                <Route path="/add-reward" element={<AddReward />} />
                <Route path="/edit-reward/:id" element={<EditReward />} />
                <Route path="/rewards/:id" element={<RewardDetails />} />
                <Route path="/users/:id" element={<UserDetails />} />
                <Route path="*" exact={true} element={<NotFound />} />
              </Routes>
            )}
          </div>
        </RewardListContext.Provider>
      </ProfileContext.Provider>
    </>
  );
};

export default AppWrapper;
