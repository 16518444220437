/* eslint-disable react-hooks/exhaustive-deps */
import Header from "components/header";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "styles/pages/auth.module.scss";
import { auth } from "utils/api";
const { LoadingOutlined } = require("@ant-design/icons");
const { default: PageWrapper } = require("components/pageWrapper");

const Auth = () => {
  const [isVerify, setIsVerify] = useState(false);
  const [isError, setIsError] = useState(false);
  const { token } = useParams();
  const [search] = useSearchParams();
  const nextUrl = search.get("next");
  let navigate = useNavigate();

  useEffect(() => {
    if (token && token.length > 0) {
      setIsError(false);
      auth(token)
        .then(() => {
          setTimeout(() => {
            setIsVerify(true);
          }, 1000);

          setTimeout(() => {
            navigate(nextUrl ? nextUrl : "/");
          }, 2500);
        })
        .catch((e) => {
          console.error("Error while logging in");
          setIsError(true);
        });
    }
  }, [token]);

  if (!token || token.length === 0) {
    return (
      <div className={styles.noSession}>
        No session found, please access this page with the link in Slack.
      </div>
    );
  }

  return (
    <>
      <Header />
      <PageWrapper>
        <div className={styles.wrapper}>
          {!isError && <LoadingOutlined className={styles.loading} />}
          {isError ? (
            <>
              <h1 className={styles.title}>Error while trying to login.</h1>
              <div className={styles.hint}>
                Please make sure to access this page with the link in Slack.
              </div>
            </>
          ) : (
            <>
              <h1 className={styles.title}>
                {isVerify
                  ? "Authentication successful, redirecting..."
                  : "Authentication in progress"}
              </h1>
              <div className={styles.hint}>
                You'll be redirecting once done.
              </div>
            </>
          )}
        </div>
      </PageWrapper>
    </>
  );
};
export default Auth;
