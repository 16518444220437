import React from "react";
import PageWrapper from "components/pageWrapper";
import styles from "styles/pages/add-reward.module.scss";
import RewardForm from "components/rewardForm";
import Header from "components/header";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

const AddReward = () => {
  return (
    <>
      <Header />
      <PageWrapper>
        <div>
          <a href="/#">
            <Button icon={<LeftOutlined />} type="link" className={styles.back}>
              Back to all rewards
            </Button>
          </a>
        </div>
        <div className={styles.addRewardWrapper}>
          <h1 className={styles.title}>Add a reward</h1>
          <div>
            <RewardForm />
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default AddReward;
