import axios from "axios";
import { getApiURL } from "./utils";
import { INSIGHT_TYPES } from "./constants";

const instance = axios.create({
  // baseURL: 'http://localhost:9781',
  baseURL: getApiURL(),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const auth = (token) =>
  instance
    .post(
      `/web-app/auth`,
      {},
      {
        headers: {
          sessionId: token || localStorage.getItem("sessionId"),
        },
      }
    )
    .then(() => {
      if (token) {
        localStorage.setItem("sessionId", token);
      }
    });

export const getProfile = () =>
  instance
    .post(
      `/web-app/get-profile`,
      {},
      {
        headers: {
          sessionId: localStorage.getItem("sessionId"),
        },
      }
    )
    .then(({ data }) => data);

export const listRewards = () =>
  instance
    .post(
      `/web-app/rewards/list`,
      {},
      {
        headers: {
          sessionId: localStorage.getItem("sessionId"),
        },
      }
    )
    .then(({ data }) => data);

export const addReward = (params) =>
  instance
    .post(`/web-app/rewards/add`, params, {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    })
    .then(({ data }) => data);

export const updateReward = (params) =>
  instance
    .post(`/web-app/rewards/update`, params, {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    })
    .then(({ data }) => data);

export const claimReward = (params) =>
  instance
    .post(`/web-app/rewards/claim`, params, {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    })
    .then(({ data }) => data);

export const getUserProfile = (params) =>
  instance
    .post(`/web-app/analytics/get-user-profile`, params, {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    })
    .then(({ data }) => data);

export const getUserFeed = (params) =>
  instance
    .post(`/web-app/analytics/get-user-feed`, params, {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    })
    .then(({ data }) => data);

export const fetchInsights = (pageGiver = 1, pageReceiver = 1) =>
  instance
    .post(
      `/web-app/analytics/fetch`,
      {
        items: [
          { type: INSIGHT_TYPES.ACTIVE_USERS_30D },
          { type: INSIGHT_TYPES.TOP_USER_PER_VALUE },
          { type: INSIGHT_TYPES.SEEDS_EXCHANGED_ALL_TIME },
          { type: INSIGHT_TYPES.SEEDS_EXCHANGED_LAST_WEEKS },
          {
            type: INSIGHT_TYPES.USER_RANKING,
            params: {
              type: "GIVER",
              from: (pageGiver - 1) * 10,
              to: pageGiver * 10,
            },
          },
          {
            type: INSIGHT_TYPES.USER_RANKING,
            params: {
              type: "RECEIVER",
              from: (pageReceiver - 1) * 10,
              to: pageReceiver * 10,
            },
          },
          { type: INSIGHT_TYPES.VALUES_USAGE },
          { type: INSIGHT_TYPES.TREES_PLANTED_AND_CO2 },
        ],
      },
      {
        headers: {
          sessionId: localStorage.getItem("sessionId"),
        },
      }
    )
    .then(({ data }) => data);

export const getLeaderboard = (params) =>
  instance
    .post(`/web-app/analytics/get-leaderboard`, params, {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    })
    .then(({ data }) => data);

export const exportRecognitions = (params) =>
  instance.get(`/web-app/analytics/export-recognitions`, {
    headers: {
      sessionId: localStorage.getItem("sessionId"),
    },
    params: { ...params },
    responseType: "blob",
  });
