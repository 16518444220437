import styles from "styles/components/pageWrapper.module.scss";

const PageWrapper = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
    {children}
    </div>
  </div>
);

export default PageWrapper;
