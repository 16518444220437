/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes } from "react-router-dom";
import Auth from "pages/auth";

import AppWrapper from "appWrapper";
import Logout from "pages/logout";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<AppWrapper />} />
      <Route path="/auth/:token?" element={<Auth />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;
