import React from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  // Legend,
  Line as LineRecharts,
} from "recharts";

const COLOR_LINE = "#39a778";

const Line = ({ data, name, width = 480 }) =>
  data ? (
    <LineChart
      width={width}
      height={400}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis
        dataKey="name"
        minTickGap={2}
        tickSize={3}
        interval={"preserveStartEnd"}
      />
      <YAxis
        domain={[
          0,
          `dataMax + ${Math.max(
            Math.floor(data[data.length - 1].amt * 0.1),
            4
          )}`,
        ]}
      />
      <Tooltip />
      {/* <Legend /> */}
      <LineRecharts
        type="monotone"
        dataKey={name}
        stroke={COLOR_LINE}
        activeDot={{ r: 6 }}
        label={({ value, x, y, stroke, index }) =>
          index === data.length - 1 ? (
            <text
              x={x}
              y={y}
              dy={-14}
              fill={COLOR_LINE}
              fontSize={22}
              textAnchor="middle"
            >
              {value}
            </text>
          ) : (
            <></>
          )
        }
      />
    </LineChart>
  ) : null;

export default Line;
