import { InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Tag, Tooltip } from "antd";
import styles from "styles/components/dataVignetteWrapper.module.scss";
import posthog from "posthog-js";
import { isLocalhost, isSuperAdmin } from "utils/utils";
import { useContext } from "react";
import ProfileContext from "./profileContext";

const DataVignetteWrapper = ({
  large = false,
  title,
  subtitle,
  children,
  infoMessage,
  background = "#fcf8f4",
  additionalOption,
  isNew = false,
}) => {
  const { profile } = useContext(ProfileContext);

  return (
    <div className={`${styles.wrapper} ${large ? styles.large : ""}`}>
      <div className={styles.inner} style={{ backgroundColor: background }}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{title}</div>
          {isNew && (
            <Badge count={"New"} offset={[-5, -2]}></Badge>
          )}
          {infoMessage && infoMessage.length > 0 && (
            <div className={styles.infoTip}>
              <Tooltip title={infoMessage}>
                <Tag className={styles.tag} bordered>
                  <InfoCircleOutlined /> Info
                </Tag>
              </Tooltip>
            </div>
          )}
          {additionalOption && (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <a
                        href={additionalOption.link}
                        onClick={() => {
                          if (!isLocalhost() && !isSuperAdmin(profile)) {
                            posthog.capture("click option vignette insights", {
                              property: additionalOption.title,
                            });
                          }
                        }}
                      >
                        {additionalOption.title}
                      </a>
                    ),
                  },
                ],
              }}
              trigger={["click"]}
            >
              <MoreOutlined className={styles.more} />
            </Dropdown>
          )}
        </div>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default DataVignetteWrapper;
