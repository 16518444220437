export const API_DEV_URL = "https://api-dev.seed.hr";
export const API_URL = "https://api.seed.hr";

export const CLOUDINARY = {
  CLOUD_NAME: "dn2epdcz4",
  UPLOAD_PRESETS: "ml_default",
};

export const INSIGHT_TYPES = {
  ACTIVE_USERS_30D: "ACTIVE_USERS_30D",
  TOP_USER_PER_VALUE: "TOP_USER_PER_VALUE",
  SEEDS_EXCHANGED_ALL_TIME: "SEEDS_EXCHANGED_ALL_TIME",
  SEEDS_EXCHANGED_LAST_WEEKS: "SEEDS_EXCHANGED_LAST_WEEKS",
  USER_RANKING: "USER_RANKING",
  VALUES_USAGE: "VALUES_USAGE",
  TREES_PLANTED_AND_CO2: "TREES_PLANTED_AND_CO2",
};

export const USER_RANKINGS = {
  GIVER: "GIVER",
  RECEIVER: "RECEIVER",
};

export const ITEM_TYPES = {
  ALL: "ALL",
  GIVEN: "GIVEN",
  RECEIVED: "RECEIVED",
  CLAIM: "CLAIM",
};

export const RANKING_PAGE_SIZE = 10;

export const SEARCH_PARAMS_NAME = {
  PAGE_RECEIVER: "pagereceiver",
  PAGE_GIVER: "pagegiver",
};

export const FILTERS_SEARCH_PARAMS = {
  TYPE: "type",
  VALUE: "value",
  DATE: "date",
  INACTIVE: 'inactive',
};

export const FILTER_TYPE = {
  ALL: "all",
  RECEIVED: "received",
  GIVEN: "given",
};

export const DATE_FILTERS = {
  LAST_7_DAYS: "LAST_7_DAYS",
  LAST_30_DAYS: "LAST_30_DAYS",
  LAST_3_MONTH: "LAST_3_MONTH",
  ALL_TIMES: "ALL_TIMES",
  CUSTOM: "CUSTOM",
};
