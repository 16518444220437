import { Button, Typography } from "antd";
import styles from "styles/components/rewardVignette.module.scss";
import { useContext, useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import ProfileContext from "./profileContext";
import ClaimButton from "./claimButton";

const RewardVignette = ({ reward }) => {
  const { profile } = useContext(ProfileContext);

  const [isAlreadyClaimed, setIsAlreadyClaimed] = useState(false);

  useEffect(() => {
    setIsAlreadyClaimed(
      !!(profile?.claimRequests || []).find((e) => e.reward._id === reward._id)
    );
  }, [profile, reward]);
  return (
    <div className={styles.wrapper}>
      {profile.isAdmin && (
        <div className={styles.adminActions}>
          <a href={`/#/edit-reward/${reward._id}`}>
            <Button
              type="link"
              size="small"
              icon={<EditOutlined />}
              className={styles.edit}
            >
              Edit
            </Button>
          </a>
        </div>
      )}
      <div className={styles.inner}>
        <div className={styles.container}>
          <div
            className={styles.picture}
            style={{
              backgroundImage: `url(${
                reward.image || "/img/default_reward.png"
              })`,
            }}
          ></div>
          <div className={styles.content}>
            <div className={styles.title}>{reward.title}</div>
            <div className={styles.description}>{reward.description}</div>

            <div className={styles.detailsLink}>
              <a href={`/#/rewards/${reward._id}`}>View details</a>
            </div>
            {isAlreadyClaimed && (
              <Typography.Text mark className={styles.claimed}>
                Note that you already claimed this reward in the past
              </Typography.Text>
            )}
          </div>

          <div className={styles.actions}>
            <ClaimButton reward={reward} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardVignette;
